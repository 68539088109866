<template>
  <div id="record">
    <v-container>
      <!-- 快選日期 -->
      <v-row class="pt-3 px-3">
        <v-col
          v-for="(item, index) in dataBtnText"
          :key="index"
          class="rounded-lg mx-1 subtitle-2 px-0 py-2 text-center d-flex align-center justify-center"
          :class="[
            dateBtn === index
              ? 'secondary white--text'
              : 'inputBg subtitle--text',
          ]"
          @click="changeData(...Object.keys(item), index)"
        >
          {{ Object.values(item)[0] }}
        </v-col>
        <!-- 自定義 data -->
        <v-col
          class="rounded-lg mx-1 subtitle-2 px-0 py-2 text-center d-flex align-center justify-center"
          :class="[
            dateBtn === 4 ? 'secondary white--text' : 'inputBg subtitle--text',
          ]"
          @click="customDate"
        >
          {{ $t('myCenter.customize') }}
        </v-col>
      </v-row>

      <v-row v-show="showPickerDate">
        <v-col cols="12">
          <CustomizeDate @get-data="pickerDateEv" />
        </v-col>
      </v-row>

      <v-row
        class="px-2"
        :class="{'pt-3': !showPickerDate}"
      >
        <v-col
          v-for="(item, index) in recordItem"
          :key="index"
          :cols="nation === 'china' ? 3 : 6"
          class="pa-1"
        >
          <div
            class="record card1 rounded d-flex flex-column align-center justify-center h-79"
          >
            <div
              class="record__title subtitle-2"
              :class="[
                Object.keys(item)[0] === 'winlost'
                  ? 'primary--text'
                  : 'title--text',
              ]"
            >
              {{ record[Object.keys(item)[0]] }}
            </div>
            <div class="record__num caption subtitle--text mt-1 px-1 text-center">
              {{ Object.values(item)[0] }}
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- wallet -->
      <v-row class="px-3 pb-2">
        <template
          v-if="
            (record.wallet && record.wallet.length < 1) || record.length === 0
          "
        >
          <v-col cols="12">
            <Nodata :text="$t('global.noRecords')" />
          </v-col>
        </template>

        <template v-else>
          <v-col
            v-for="item in record.wallet"
            :key="item.code"
            cols="12"
            class="card1 rounded my-1 pa-0 caption rounded-t"
          >
            <!-- 遊戲名稱 -->
            <div class="card2 px-4 py-1 title--text rounded-t">
              {{ item.title }}
            </div>

            <!-- 總投注 -->
            <div class="px-4 pt-4 title--text">
              <span class="subtitle--text mr-3">
                {{ $t('myCenter.totalWager') }}
              </span>
              {{ item.bet }}
            </div>

            <!-- 總輸贏 -->
            <div class="px-4 pb-4 pt-1 subtile--text">
              <span class="subtitle--text mr-3">
                {{ $t('myCenter.totalWinloss') }}
              </span>
              <span class="primary--text">
                {{ item.winlost }}
              </span>
            </div>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { timezone, getDayRange } from '@/utils/day'

import CustomizeDate from '../../record/CustomizeDate'
import { resStatus } from '@/utils/resUtils'
import Nodata from '@/components/base/Nodata'

export default {
  components: { CustomizeDate, Nodata },

  data: () => ({
    dateBtn: 0,

    showPickerDate: false,

    form: {
      trans_at: '', // 2020-07-01,2020-07-20	起始時間,結束時間
      timezone: '', // 時區
    },

    record: [],
  }),

  computed: {
    ...mapGetters(['nation']),

    /**
     * 快捷日期選擇按鈕
     * @date 2021-05-19
     * @returns {array} 快捷日期選擇按鈕 (index => 日期區間天數)
     */
    dataBtnText() {
      return [
        { 0: this.$t('myCenter.today') },
        { 1: this.$t('myCenter.yesterday') },
        { 6: this.$t('myCenter.Last7days') },
        { 29: this.$t('myCenter.Last30days') },
      ]
    },

    /**
     * 資金明細 項目¸
     * @date 2021-05-19
     * @returns {array} 資金明細 項目組
     */
    recordItem() {
      return [
        // 總存款
        { deposit: this.$t('myCenter.totalDeposit') },
        // 總取款
        { withdraw: this.$t('myCenter.totalWithdraw') },
        // 總投注
        { bet: this.$t('myCenter.totalWager') },
        // 總輸贏
        { winlost: this.$t('myCenter.totalWinloss') },
      ]
    },
  },

  watch: {
    dateBtn(newTab) {
      if (newTab !== 4) this.showPickerDate = false
    },
  },

  mounted() {
    this.form.timezone = timezone()
    this.form.trans_at = getDayRange(0)
    this.getUserWalletRecord(this.form)
  },

  methods: {
    ...mapActions(['get_user_wallet_record', 'show_alert']),

    /**
     * 取得資金明細 (成功)
     * @date 2021-05-19
     * @param {any} data
     * @returns {any}
     */
    getUserWalletRecordSuccess(data) {
      this.record = data?.detail
    },

    /**
     * 取得資金明細 (失敗) 超過 30 天日期?
     * @date 2021-05-19
     */
    getUserWalletRecordFail() {
      this.record = []
      this.show_alert({
        icon: 'fail',
      })
    },

    /**
     * 取得資金明細
     * @date 2021-05-19
     * @param {Object} form {trans_at, timezone}
     */
    async getUserWalletRecord(form) {
      const res = await this.get_user_wallet_record(form)
      resStatus(
        res,
        this.getUserWalletRecordSuccess,
        this.getUserWalletRecordFail,
      )
    },

    /**
     * 切換日期按鈕
     * @date 2021-03-17
     * @param {Number} dayNum 日期區間 從今天起算幾天前
     * @param {Number} index 按鍵索引
     */
    changeData(dayNum, index) {
      // 已在當前 tab 不再重複打 api
      if (this.dateBtn === index) return false

      this.dateBtn = index
      this.form.trans_at = getDayRange(dayNum)
      this.getUserWalletRecord(this.form)
    },

    /**
     * 自定義功能
     * @date 2021-05-18
     */
    customDate() {
      if (this.dateBtn === 4) return false

      this.dateBtn = 4
      this.showPickerDate = true

      this.form.trans_at = getDayRange(0)
      this.getUserWalletRecord(this.form)
    },

    /**
     * 日期選擇器選取日期 (傳父)
     * @date 2021-05-19
     * @param {string} date 日期區間
     */
    pickerDateEv(date) {
      this.form.trans_at = date
      this.getUserWalletRecord(this.form)
    },
  },
}
</script>

<style lang="scss" scoped>
.h-79 {
	height: 79px;
}
</style>
