<template>
  <v-container :class="currentDevice === 'ios' ? 'pb-72' : 'pb-56'">
    <!-- 替換頭像 DIALOG -->
    <Dialog
      :dialog="dialog.status"
      :width="320"
      :height="302"
    >
      <!-- 我的頭像 -->
      <span
        slot="title"
        class="text-h6"
      >
        {{ $t('myCenter.myAvatar') }}
      </span>

      <div
        slot="text"
        class="d-flex flex-column align-center"
      >
        <!-- 預覽頭像 -->
        <v-avatar
          size="83"
          color="primary"
          class="mt-2"
          :style="avatarBorderClr"
        >
          <img
            v-if="userBaseInfo.avatar || dialog.form.avatar"
            :src="baseImgUrl + dialog.form.avatar || baseImgUrl + userBaseInfo.avatar"
            alt="userBaseInfo.username"
          >
        </v-avatar>

        <!-- 頭像預覽 -->
        <span class="caption title--text mt-1">
          {{ $t('myCenter.avatarPreview') }}
        </span>

        <div class="d-flex justify-space-between w-100 mt-1 px-6">
          <!-- 頭像列表 -->
          <v-avatar
            v-for="item in dialog.avatarList"
            :key="item"
            size="48"
            color="primary"
            class="mt-2"
            :style="dialog.form.avatar === item ? avatarBorderClr : ''"
            @click="dialog.form.avatar = item"
          >
            <img :src="baseImgUrl + item">
          </v-avatar>
        </div>
      </div>
      <v-btn
        slot="btn"
        text
        class="subtitle"
        @click="closeDialog"
      >
        {{ $t('global.cancel') }}
      </v-btn>
      <v-btn
        slot="btn"
        text
        class="primary--text"
        @click="setUserAvatar"
      >
        {{ $t('global.save') }}
      </v-btn>
    </Dialog>

    <!-- 使用者資訊 -->
    <v-row class="userInfo card1">
      <v-col
        cols="12"
        class="text-center"
      >
        <v-row>
          <v-col
            cols="12"
            class="userInfo__avatar pb-2"
          >
            <!-- 一般模式 (頭像)-->
            <v-avatar
              size="83"
              class="mt-2"
              :style="avatarBorderClr"
              @click="preChangeUserAvatar"
            >
              <img
                v-if="userBaseInfo.avatar"
                :src="baseImgUrl + userBaseInfo.avatar"
                alt="userBaseInfo.username"
              >
            </v-avatar>
          </v-col>

          <!-- 如果未登入 -->
          <template v-if="!isLogin">
            <v-col class="pt-2">
              <!-- 請先登入 btn -->
              <v-btn
                to="/login"
                class="mb-4"
                outlined
                color="primary"
              >
                <span class="subtitle-2 primary--text">
                  {{ $t('flashMessage.loginFirst') }}
                </span>
              </v-btn>
            </v-col>
          </template>

          <!-- 如果已經登入 -->
          <template v-else>
            <!-- 使用者名稱 -->
            <v-col
              cols="12"
              class="userInfo__name pt-0 pb-3 subtitle-2"
            >
              <!-- 一般模式 -->
              <span class="mr-1">{{ userData.member.account }}</span>

              <Icon
                v-if="showVip"
                data="@icon/vip/diamond.svg"
                width="16px"
                height="13px"
                color="#01D0FB #DFF6FD #99E6FC #F2FBFF #DFF6FD #C8EFFE #99E6FC #62DBFB"
              />
            </v-col>

            <template v-if="showVip">
              <v-col
                cols
                class="userInfo__progress vip d-flex align-center py-0 px-10"
              >
                <!-- 目前等級 icon -->
                <v-img
                  v-if="nowVipData.logo"
                  class="vip__now"
                  :src="baseImgUrl + nowVipData.logo"
                  max-height="28"
                  max-width="28"
                />
                <!-- 進度條 -->
                <v-progress-linear
                  class="mx-1 vip__progress"
                  rounded
                  :value="vipProgress.progress"
                  color="primary"
                  height="9"
                />

                <!-- 下一等級 icon -->
                <v-img
                  v-if="nextVipData.logo"
                  class="vip__next"
                  :src="baseImgUrl + nextVipData.logo"
                  max-height="28"
                  max-width="28"
                />
              </v-col>

              <!-- vip 說明文字 -->
              <v-col cols="12 text-left pb-4 subtitle-2 px-8 pt-0">
                <div class="vip__description caption comment--text text-center">
                  {{
                    $t('myCenter.myVipLevel') +
                      ': ' +
                      (nowVipData.name ? nowVipData.name : '--')
                  }}

                  <span v-if="nextVipData">
                    {{
                      $t('myCenter.myVipCondition', {
                        deposit: upgradeNeedDeposit,
                        turnover: upgradeNeedTurnover,
                      }) +
                        ' ' +
                        nextVipData.name
                    }}
                  </span>
                </div>
              </v-col>
            </template>
          </template>
        </v-row>
      </v-col>
    </v-row>

    <v-row
      v-if="style !== 'darkGD'"
      class="card1 pb-2"
    >
      <v-divider class="divider" />
    </v-row>

    <!-- 錢包相關操作 -->
    <v-row class="walletArea card1">
      <v-col cols="12">
        <v-row>
          <!-- 中心錢包 -->
          <template v-if="style !== 'darkGD'">
            <v-col
              cols="4"
              class="text-left primary--text py-0 d-flex align-center font-weight-bold pl-6 caption"
            >
              {{ $t('myCenter.mainWallet') }}
            </v-col>
            <v-col
              cols="8"
              class="text-right title--text py-0 pr-6"
            >
              <span>{{ $t(`global.${currencySymbol}`) }}</span>
              <span class="user-wallet ml-1">{{ userWallet }}</span>
            </v-col>
          </template>

          <template v-else>
            <!-- 中心錢包 (style === 'darkGD') -->
            <v-col
              cols="12"
              class="px-4"
            >
              <div class="d-flex justify-space-between align-center dark-bg px-4 wallet-center">
                <div class="subtitle-2 d-flex align-center font-weight-bold">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-1"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 8c0-4.412 3.588-8 8-8s8 3.588 8 8-3.588 8-8 8-8-3.588-8-8zm8.645-5.294c.987.137 1.686.631 2.276 1.248L9.906 5.106c-.562-.52-1.056-.809-1.755-.809-.81 0-1.262.384-1.262 1.138 0 .726.759 1.094 1.63 1.517 1.169.568 2.54 1.234 2.54 2.995 0 1.495-.837 2.606-2.414 2.908v1.59H7.287V12.91c-.933-.11-1.989-.535-2.716-1.193l.892-1.358c.754.59 1.481.932 2.263.932.96 0 1.399-.425 1.399-1.206 0-.825-.828-1.25-1.746-1.721-1.142-.586-2.424-1.243-2.424-2.833 0-1.494.892-2.523 2.332-2.797V1.143h1.358v1.563z"
                      fill="#fff"
                    />
                  </svg>

                  {{ $t('myCenter.mainWallet') }}
                </div>
                <div class="d-flex align-center">
                  <span>{{ $t(`global.${currencySymbol}`) }}</span>
                  <span class="user-wallet ml-1">{{ userWallet }}</span>
                </div>
              </div>
            </v-col>
          </template>

          <!-- 快捷功能 -->
          <v-col
            cols="3"
            class="text-center d-flex justify-center align-center pa-0"
          >
            <!-- 卡片 -->
            <v-btn
              block
              text
              height="56"
              class="px-0"
              @click="goPageFunc('user/card')"
            >
              <div class="d-flex flex-column align-center justify-center icon--text caption font-weight-bold">
                <Icon
                  data="@icon/user/walletItem/card.svg"
                  width="23px"
                  height="20px"
                  class="mr-1"
                  :class="[style === 'darkGD' ? 'primary--text' : '']"
                />
                {{ $t('global.cards') }}
              </div>
            </v-btn>
          </v-col>

          <v-col
            cols="3"
            class="text-center d-flex justify-center align-center pa-0"
          >
            <!-- 取款 -->
            <v-btn
              block
              to="user/withdrawal"
              text
              height="56"
              class="px-0"
            >
              <div class="d-flex flex-column align-center justify-center icon--text caption font-weight-bold">
                <Icon
                  data="@icon/user/walletItem/withdrawal.svg"
                  width="23px"
                  height="20px"
                  class="mr-1"
                  :class="[style === 'darkGD' ? 'primary--text' : '']"
                />
                {{ $t('global.withdraw') }}
              </div>
            </v-btn>
          </v-col>
          <v-col
            cols="3"
            class="text-center d-flex justify-center align-center pa-0"
          >
            <!-- 轉換 -->
            <v-btn
              block
              to="user/transfer"
              text
              height="56"
              class="px-0"
            >
              <div class="d-flex flex-column align-center justify-center icon--text caption font-weight-bold">
                <Icon
                  data="@icon/user/walletItem/transfer.svg"
                  width="23px"
                  height="20px"
                  class="mr-1"
                  :class="[style === 'darkGD' ? 'primary--text' : '']"
                />
                {{ $t('global.transferBtn') }}
              </div>
            </v-btn>
          </v-col>
          <v-col
            cols="3"
            class="text-center d-flex justify-center align-center pa-0"
          >
            <!-- 存款 -->
            <v-btn
              block
              text
              height="56"
              class="px-0"
              @click="goPageFunc('user/deposit')"
            >
              <div class="d-flex flex-column align-center justify-center icon--text caption font-weight-bold">
                <Icon
                  data="@icon/user/walletItem/deposit.svg"
                  width="23px"
                  height="20px"
                  class="mr-1"
                  :class="[style === 'darkGD' ? 'primary--text' : '']"
                />
                {{ $t('global.deposit') }}
              </div>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- 掃碼登入 -->
    <v-row
      v-if="style === 'darkGD'"
      class="pa-0"
    >
      <v-col class="pa-0">
        <v-btn
          class="dark-bg"
          height="48"
          block
          tile
          @click="goQrCode"
        >
          <Icon
            data="@icon/scan.svg"
            width="24"
            height="24"
            color="white"
          />
          <span class="ml-1">{{ $t('game.scanLogin') }}</span>
        </v-btn>
      </v-col>
    </v-row>

    <!-- 其它頁面導引 -->
    <v-row>
      <!-- 交易紀錄 -->
      <v-col cols="12">
        <v-row class="px-4 py-3">
          <v-col
            :cols="itemsWidth"
            class="text-center px-1 py-1"
          >
            <v-btn
              block
              height="80"
              color="card1"
              :style="style === 'darkGD'? {border: `1px solid ${primaryColor}`} : ''"
              @click="goPageFunc('user/trade')"
            >
              <div class=" d-flex flex-column align-center justify-center icon--text">
                <Icon
                  data="@icon/user/mainControl/transfer.svg"
                  width="48px"
                  height="48px"
                  :class="[style === 'darkGD' ? 'primary--text' : '']"
                />
                <span class="caption font-weight-bold">
                  {{ $t('global.transaction') + $t('global.record') }}
                </span>
              </div>
            </v-btn>
          </v-col>

          <!-- 投注紀錄 -->
          <v-col
            :cols="itemsWidth"
            class="text-center px-1 py-1"
          >
            <v-btn
              to="user/wager"
              block
              height="80"
              color="card1"
              :style="style === 'darkGD'? {border: `1px solid ${primaryColor}`} : ''"
            >
              <div class="h-100 d-flex flex-column align-center justify-center icon--text">
                <Icon
                  data="@icon/user/mainControl/wager.svg"
                  width="48px"
                  height="48px"
                  class="pa-2"
                  :class="[style === 'darkGD' ? 'primary--text' : '']"
                />
                <span class="caption font-weight-bold">
                  {{ $t('global.wager') + $t('global.record') }}
                </span>
              </div>
            </v-btn>
          </v-col>

          <!-- 兌獎紀錄 -->
          <v-col
            :cols="itemsWidth"
            class="text-center px-1 py-1"
          >
            <v-btn
              to="user/prize"
              block
              height="80"
              color="card1"
              :style="style === 'darkGD'? { border: `1px solid ${primaryColor}` } : ''"
            >
              <div class="h-100 d-flex flex-column align-center justify-center icon--text">
                <Icon
                  data="@icon/user/mainControl/gift.svg"
                  width="48"
                  height="48"
                  :class="[style === 'darkGD' ? 'primary--text' : '']"
                />
                <span class="caption font-weight-bold">
                  {{ $t('global.prize') + $t('global.record') }}
                </span>
              </div>
            </v-btn>
          </v-col>

          <!-- 個人資料 -->
          <v-col
            :cols="itemsWidth"
            class="text-center px-1 py-1"
          >
            <v-btn
              to="user/personal"
              block
              height="80"
              color="card1"
              :style="style === 'darkGD'? {border: `1px solid ${primaryColor}`} : ''"
            >
              <div class="d-flex flex-column align-center justify-center icon--text">
                <ProfileIcon
                  width="40px"
                  height="30px"
                  class="ma-2"
                  :color="style === 'darkGD' ? primaryColor : profileClr"
                />
                <span class="caption font-weight-bold">
                  {{ $t('myCenter.personalInformation') }}
                </span>
              </div>
            </v-btn>
          </v-col>

          <!-- vip 專屬 -->
          <v-col
            v-if="showVip"
            :cols="itemsWidth"
            class="text-center px-1 py-1 "
          >
            <v-btn
              to="user/vip"
              block
              height="80"
              color="card1"
              :style="style === 'darkGD'? {border: `1px solid ${primaryColor}`} : ''"
            >
              <div class="h-100 d-flex flex-column align-center justify-center icon--text">
                <Icon
                  data="@icon/user/mainControl/vip.svg"
                  width="48px"
                  height="48px"
                  class="pa-2"
                  :class="[style === 'darkGD' ? 'primary--text' : '']"
                />
                <span class="caption font-weight-bold">
                  {{ $t('myCenter.myVip') }}
                </span>
              </div>
            </v-btn>
          </v-col>

          <!-- 常見問題 -->
          <v-col
            v-else
            :cols="itemsWidth"
            class="text-center px-1 py-1 "
          >
            <v-btn
              to="faq/problem"
              block
              height="80"
              color="card1"
              :style="style === 'darkGD'? {border: `1px solid ${primaryColor}`} : ''"
            >
              <div class="h-100 d-flex flex-column align-center justify-center icon--text">
                <Icon
                  data="@icon/user/mainControl/help.svg"
                  width="48px"
                  height="48px"
                  class="pa-2"
                  :class="[style === 'darkGD' ? 'primary--text' : '']"
                />
                <span class="caption font-weight-bold">
                  {{ $t('myCenter.commonProblem') }}
                </span>
              </div>
            </v-btn>
          </v-col>

          <!-- 消息中心 -->
          <v-col
            :cols="itemsWidth"
            class="text-center px-1 py-1"
          >
            <v-btn
              to="user/news"
              block
              height="80"
              color="card1"
              :style="style === 'darkGD'? {border: `1px solid ${primaryColor}`} : ''"
            >
              <div class="h-100 d-flex flex-column align-center justify-center icon--text">
                <Icon
                  data="@icon/user/mainControl/msg.svg"
                  width="48px"
                  height="48px"
                  class="pa-2"
                  :class="[style === 'darkGD' ? 'primary--text' : '']"
                />
                <span class="caption font-weight-bold">
                  {{ $t('myCenter.news') }}
                </span>
              </div>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <!-- 頁面連結 -->
      <v-col
        cols="12"
        class="pt-1 px-4"
      >
        <router-link
          v-for="(item, index) in pageItem"
          v-show="!noShowRouterLink.includes(Object.keys(item)[0])"
          :key="index"
          class="text-decoration-none title--text pageLink"
          :to="`/${Object.keys(item)[0]}`"
        >
          <v-divider class="pb-3 divider" />
          <div class="d-flex mb-2 px-2">
            <span class="caption font-weight-medium">
              {{ Object.values(item)[0] }}
            </span>
            <v-spacer />
            <v-icon right>
              mdi-chevron-right
            </v-icon>
          </div>
        </router-link>

        <!-- 切換語系 -->
        <div class="text-decoration-none title--text pageLink">
          <v-divider class="pb-3 divider" />
          <div
            class="d-flex mb-2 px-2"
            @click="language.status = !language.status"
          >
            <span class="caption font-weight-medium">
              {{ $t('myCenter.language') }}
            </span>
            <v-spacer />
            <v-icon right>
              mdi-chevron-right
            </v-icon>
          </div>
        </div>

        <!-- 登出 -->
        <div
          v-if="isLogin"
          class="title--text pageLink"
          @click="userLogout"
        >
          <v-divider class="pb-3 divider" />
          <div class="d-flex mb-2 px-2">
            <span class="caption font-weight-medium">
              {{ $t('subHeader.logout') }}
            </span>
            <v-spacer />
            <v-icon right>
              mdi-chevron-right
            </v-icon>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- 語系 popup -->
    <v-bottom-sheet
      v-model="language.status"
      :fullscreen="true"
    >
      <v-sheet
        class="text-center"
        height="200px"
      >
        <v-card
          class="mx-auto bg"
          height="100vh"
        >
          <v-card-text class="pa-0">
            <!-- 關閉切換語系 -->
            <v-toolbar class="bg">
              <v-btn
                icon
                @click="language.status = !language.status"
              >
                <Icon
                  data="@icon/toolbar/arrow_left.svg"
                  width="16px"
                  height="16px"
                  class="icon--text"
                />
              </v-btn>
              <v-toolbar-title>
                {{ $t('myCenter.language') }}
              </v-toolbar-title>
              <v-spacer />
            </v-toolbar>

            <!-- 搜尋 -->
            <v-list
              three-line
              subheader
              class="bg"
            >
              <div class="search-area">
                <v-text-field
                  id="searchInput"
                  v-model.trim="language.searchKeyword"
                  autofocus
                  class="mx-4 mt-2 inputBg"
                  hide-details
                  :placeholder="$t('game.searchGame')"
                  rounded
                  autocomplete="off"
                  dense
                >
                  <!-- 放大鏡 -->
                  <div
                    slot="prepend-inner"
                    class="mt-0"
                  >
                    <v-btn
                      slot="btn"
                      icon
                    >
                      <Icon
                        data="@icon/search.svg"
                        width="24"
                        height="24"
                        class="white--text"
                      />
                    </v-btn>
                  </div>
                </v-text-field>
              </div>
            </v-list>

            <v-list
              v-for="lang in language.resultLangs"
              :key="lang._id"
              class="bg text-left px-4 py-0"
            >
              <!-- 切換本地語系 -->
              <v-list-item
                :class="[{'primary rounded-xl': activeLang === lang.code}]"
                @click="settingClientLang(lang.code)"
              >
                <!-- ICON -->
                <div class="local-name mr-3 title--text">
                  <v-img
                    :src="baseImgUrl + lang.icon"
                    max-width="20"
                  />
                </div>
                <!-- 默認名稱 -->
                <div class="default-name title--text">
                  {{ lang.default_name }}
                </div>
              </v-list-item>
              <v-divider class="divider" />
            </v-list>
          </v-card-text>
        </v-card>
      </v-sheet>
    </v-bottom-sheet>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { resStatus } from '@/utils/resUtils'
import ProfileIcon from '@/components/icon/Profile'
import Dialog from '@/components/dialog/index'
import { deviceCheck } from '../../utils/device'
import { getCookie } from '../../utils/cookie'

export default {
  components: {
    ProfileIcon,
    Dialog,
  },

  data: () => ({
    userBaseInfo: [],

    dialog: {
      status: false,
      avatarList: [],
      form: {
        avatar: '',
      },
    },

    // 當前語系
    activeLang: null,
    langSheetStatus: false, // 語系開關
    language: {
      status: false,
      searchKeyword: '',
      resultLangs: [],
    },
  }),

  computed: {
    ...mapState({
      isLogin: state => state.user.isLogin,
      userData: state => state.user.userData,
      theme: state => state.theme,
      userWallet: state => state.wallet.main,

      primaryColor() {
        return this.$vuetify.theme.themes[this.theme]?.primary || ''
      },

      // 此站點是否顯示 vip 相關功能
      vipStatus: state => state.websiteData.func_switch.vip,

      nowVipData: state => state.vip.now,
      nextVipData: state => state.vip.next,
      vipProgress: state => state.vip.progress,

      // 顯示 vip 相關
      showVip: state => state.websiteData.func_switch.vip,
      // 顯示龍虎榜
      dtrankDisplay: state => state.websiteData.func_switch.dtrank_display,

      // 可選語言
      switchLanguages: state => state.websiteData.lang_available,
    }),

    ...mapGetters([
      'baseImgUrl',
      'lang',
      'dynamicAgentDisplay',
      'clientLang', // 前端當前切換語系
      'currencySymbol', // 營商幣別
      'style',
      'currentDevice',
      'isMycardDomainService',
    ]),

    profileClr() {
      return this.$vuetify.theme.themes[this.theme]?.icon
    },

    avatarBorderClr() {
      return {
        border: `1px solid ${this.$vuetify.theme.themes[this.theme]?.primary}`,
      }
    },

    /**
     * 底部路由導引項目
     * @date 2021-05-31
     * @returns {array} 路由導引項目 (index => 路由 / value => 文字)
     */
    pageItem() {
      const page = [
        {
          'faq/help': this.$t('footer.新手幫助'),
        },
        {
          'faq/problem': this.$t('myCenter.commonProblem'),
        },
        {
          app: this.$t('menu.app'),
        },
        {
          recommend: this.$t('myCenter.recommendBonus'),
        },
        {
          'faq/corporate-affairs': this.$t('myCenter.responsibility'),
        },
        {
          agent: this.$t('myCenter.joinUs'),
        },
      ]

      if (this.dtrankDisplay) {
        page.unshift({
          'dt-list': this.$t('game.dragonTigerList'),
        })
      }

      return page
    },

    // 因應語系動態寬度
    itemsWidth() {
      const widthAry = ['en', 'vi-VN', 'ph']
      return widthAry.includes(this.clientLang) ? 6 : 4
    },

    // 不顯示的路由名稱
    noShowRouterLink() {
      const noDisplayList = []
      if (!this.isLogin) noDisplayList.push('recommend')
      if (!this.showVip) noDisplayList.push('faq/problem')
      if (!this.dynamicAgentDisplay) noDisplayList.push('agent')

      return noDisplayList
    },

    // 升級需要的 「存款」
    upgradeNeedDeposit() {
      const needNum = this.nextVipData.deposit_amount - this.vipProgress.deposit_amount
      if (!needNum) return '--'
      if (needNum < 0) return 0
      return needNum
    },

    // 升級需要的 「流水」
    upgradeNeedTurnover() {
      const needNum = this.nextVipData.bet_amount - this.vipProgress.bet_amount
      if (!needNum) return '--'
      if (needNum < 0) return 0
      return needNum
    },
  },

  watch: {
    // 篩選語系
    'language.searchKeyword': {
      handler(searchKeyword) {
        this.language.resultLangs = this.switchLanguages.filter(lang => {
          const filterItem = ['code', 'default_name', 'localized_name']
          return filterItem.find(item => lang[item].includes(searchKeyword))
        })
      },
      immediate: true,
    },

    // 監聽切換 popup
    'language.status'(newStatus) {
      if (!newStatus) this.language.searchKeyword = ''
    },
  },

  mounted() {
    if (this.isLogin) {
      // 如果此站顯示 vip 功能，才取 vip 資料
      if (this.vipStatus) this.getUserVipData()
      this.getUserBaseInfo()
      this.getUserBalance()
    }
    // 初始化「當前」語系
    const clientLang = sessionStorage.getItem('clientLang')
    if (clientLang) {
      this.activeLang = clientLang
      return false
    }
    this.activeLang = this.lang
  },

  methods: {
    ...mapActions([
      'get_user_vip',
      'get_user_base_info',
      'get_user_balance',
      'user_logout',
      'show_alert',
      'get_user_avatar_list',
      'set_user_avatar',
      'get_service_url',
      'get_website_theme',
      'get_homepage_layout_area',
    ]),

    // 設置前端語系
    settingClientLang(clientLang) {
      this.language.status = false
      if (clientLang === this.clientLang) return false

      this.$i18n.locale = clientLang
      sessionStorage.setItem('clientLang', clientLang)
      this.activeLang = clientLang

      // 更新 layout 資料
      this.get_website_theme()
      this.get_homepage_layout_area()
    },

    // TODO 取得vip資料 成功/失敗 處理
    getUserVipDataSuccess(data) {},
    getUserVipDataFail() {},

    async getUserVipData() {
      const userVipData = await this.get_user_vip()
      resStatus(
        userVipData,
        this.getUserVipDataSuccess,
        this.getUserVipDataFail,
      )
    },

    getUserBaseInfoSuccess(data) {
      this.userBaseInfo = data.member
      this.dialog.form.avatar = data.member.avatar
    },

    async getUserBaseInfo() {
      const userBaseInfo = await this.get_user_base_info()
      resStatus(userBaseInfo, this.getUserBaseInfoSuccess)
    },

    getUserBalanceSuccess(userMainWallet) {
      this.userWallet = userMainWallet.balance
    },

    /**
     * 取得使用者中心錢包
     * @date 2021-04-09
     */
    async getUserBalance() {
      const walletcode = { walletcode: 'player_balance' }
      await this.get_user_balance(walletcode)
    },

    userLogoutSuccess(data) {
      this.userBaseInfo = []
      this.show_alert({
        icon: 'done',
        // text: data.message,
      })

      setTimeout(() => {
        this.$router.push({ path: '/' })
      }, 500)
    },

    failDialog(error) {
      this.show_alert({
        icon: 'fail',
        text: error,
      })
    },

    /**
     * 使用者登出
     * @date 2021-04-09
     */
    async userLogout() {
      const res = await this.user_logout()
      resStatus(res, this.userLogoutSuccess, this.failDialog)
    },

    /**
     * 取得頭像列表
     * @date 2021-04-09
     * @returns {object} 頭像列表
     */
    async getUserAvatarList() {
      const userAvatarList = await this.get_user_avatar_list()
      return userAvatarList
    },

    setUserAvatarList(data) {
      this.dialog.avatarList = data.avatars
    },

    /**
     * 設置頭像功能 (點選頭像)
     * @date 2021-07-21
     * @param {any} e
     * @param {any} fetchAvatarList=this.getUserAvatarList(
     */
    async changeUserAvatar(e, fetchAvatarList = this.getUserAvatarList()) {
      this.dialog.status = true
      resStatus(await fetchAvatarList, this.setUserAvatarList)
    },

    closeDialog() {
      this.dialog.status = false
      this.dialog.form.avatar = this.userBaseInfo.avatar
    },

    /**
     * 設置頭像 (成功)
     * @date 2021-07-21
     */
    setUserAvatarSuccess() {
      this.getUserBaseInfo()
      this.show_alert({
        icon: 'done',
        text: this.$t('flashMessage.editAvatarSuccess'),
      })
    },

    /**
     * 設置頭像 (失敗)
     * @date 2021-07-21
     */
    setUserAvatarFail() {
      this.show_alert({
        icon: 'fail',
        text: this.$t('flashMessage.editAvatarFail'),
      })
    },

    /**
     * 設置頭像
     * @date 2021-07-21
     */
    async setUserAvatar() {
      const userAvatarUrl = { avatar: this.dialog.form.avatar }
      const res = await this.set_user_avatar(userAvatarUrl)
      this.dialog.status = false
      resStatus(res, this.setUserAvatarSuccess, this.setUserAvatarFail)
    },

    /**
     * 更換頭像前 判斷
     * 如果已登入 -> 更換頭像
     * 如果未登入 -> 登入頁面
     * @date 2021-07-29
     */
    preChangeUserAvatar() {
      if (this.isLogin) return this.changeUserAvatar()
      this.$router.push({ path: '/login' })
    },

    goQrCode() {
      // 如果尚未登入
      if (!this.isLogin) {
        return this.show_alert({
          icon: 'fail',
          text: this.$t('flashMessage.loginFirst'),
        })
      }

      const { href } = this.$router.resolve({ path: '/user' })
      const url = `${window.location.origin}/${href}`
      const device = deviceCheck()

      const data = {
        url,
        Authorization: getCookie('accessToken'),
        wtoken: sessionStorage.getItem('wtoken'),
        lang: this.lang,
      }

      this.$log(data)
      this.$log(device)

      try {
        if (device === 'ios') {
          window.webkit.messageHandlers.openQRCode.postMessage(data)
        } else if (device === 'android') {
          window.WebScript.openQRCode(JSON.stringify(data))
        } else this.$router.push({ path: '/qrcode' })
      } catch {
        this.$router.push({ path: '/qrcode' })
      }
    },

    // 卡片 && 存款 && 交易紀錄 按鈕功能
    goPageFunc(routerPath) {
      this.$router.push({ path: routerPath })
    },
  },
}
</script>

<style lang="scss" scoped>
.user-wallet {
	font-size: 28px;
}

.wallet-center {
	height: 36px;
	border-radius: 16px;
}

.dark-bg {
	background-image: linear-gradient(96deg, #e6ce71 -6%, #bc954d 19%, #a57539 49%, #c09a50 77%, #e6ce71 100%), linear-gradient(96deg, #e6ce71 -6%, #bc954d 19%, #a57539 49%, #c09a50 77%, #e6ce71 100%);
}

.user-wallet {
	font-size: 24px;
}

</style>
