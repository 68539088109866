<template>
  <div id="profile">
    <v-form
      ref="form"
      v-model="veridate"
    >
      <v-container>
        <v-row class="pt-4 pb-3">
          <!-- 開戶姓名 -->
          <v-col
            cols="12"
            class="px-3 py-0"
          >
            <span class="title--text caption font-weight-medium">
              {{ $t("myCenter.bankAccountName") }}
            </span>
            <!-- :disabled="disable.username" -->
            <v-text-field
              v-model.trim="form.username"
              :disabled="disable.username"
              :readonly="disable.username"
              :placeholder="$t('form.missName')"
              :rules="[rules.required.userName, rules.length.userName]"
              dense
              class="mt-2"
              single-line
              outlined
            >
              <div
                v-if="canChangeUsername && disable.username"
                slot="append"
              >
                <Icon
                  data="@icon/edit.svg"
                  width="24px"
                  height="24px"
                  class="primary--text cursor-pointer"
                  @click="editItem('username')"
                />
              </div>
            </v-text-field>
          </v-col>

          <!-- 性別 -->
          <v-col
            cols="12"
            class="px-3 py-0"
          >
            <span class="title--text caption font-weight-medium">
              {{ $t("myCenter.gender") }}
            </span>
            <v-text-field
              :placeholder="$t('form.missGender')"
              :value="genderDisplay"
              :disabled="disable.gender"
              :rules="[rules.required.gender]"
              class="mt-2"
              :readonly="disable.gender"
              dense
              single-line
              outlined
              @click="selectDialog = true"
            >
              <div
                v-if="canChangeGender && disable.gender"
                slot="append"
              >
                <Icon
                  data="@icon/edit.svg"
                  width="24px"
                  height="24px"
                  class="primary--text cursor-pointer"
                  @click="editItem('gender')"
                />
              </div>
            </v-text-field>
          </v-col>

          <!-- 出生日期 -->
          <v-col
            cols="12"
            class="px-3 py-0"
          >
            <span class="title--text caption font-weight-medium">
              {{ $t("myCenter.birthday") }}
            </span>

            <!-- 日期選擇器彈窗 -->
            <v-dialog
              ref="dialogFrom"
              v-model="datePicker"
              persistent
              width="290px"
            >
              <!-- input -->
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="form.birthdate"
                  :disabled="disable.birthdate"
                  :readonly="disable.birthdate"
                  :placeholder="$t('form.missBirthday')"
                  :rules="[rules.required.birthdate]"
                  class="mt-2"
                  dense
                  single-line
                  outlined
                  v-bind="attrs"
                  v-on="on"
                >
                  <div
                    v-if="canChangeBirthday && disable.birthdate"
                    slot="append"
                  >
                    <Icon
                      data="@icon/edit.svg"
                      width="24px"
                      height="24px"
                      class="primary--text cursor-pointer"
                      @click="editItem('birthday')"
                    />
                  </div>
                </v-text-field>
              </template>

              <!-- 日期選擇器 -->
              <v-date-picker
                v-model="form.birthdate"
                class="bg"
                :max="legaAge"
                header-color="bg"
                color="secondary"
                scrollable
              >
                <v-spacer />
                <v-btn
                  text
                  color="subtitle"
                  class="subtitle-1 font-weight-medium"
                  @click="form.birthdate = ''"
                >
                  {{ $t("global.cancel") }}
                </v-btn>

                <v-btn
                  class="subtitle-1 font-weight-medium"
                  text
                  color="primary"
                  @click="datePicker = false"
                >
                  {{ $t("global.confirmation") }}
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>

          <!-- 手机号码 -->
          <v-col
            cols="12"
            class="px-3 py-0"
          >
            <span class="title--text caption font-weight-medium">
              {{ $t("myCenter.mobile") }}
            </span>
            <v-text-field
              v-model.trim="form.contact_no"
              :placeholder="$t('form.missMobile')"
              :disabled="disable.contact_no"
              :readonly="disable.contact_no"
              :rules="[
                rules.required.mobile,
                disable.contact_no ? '' : rules.format.mobile,
              ]"
              class="mt-2"
              type="number"
              dense
              single-line
              outlined
            >
              <div
                v-if="canChangePhoneNumber && disable.contact_no"
                slot="append"
              >
                <Icon
                  data="@icon/edit.svg"
                  width="24px"
                  height="24px"
                  class="primary--text cursor-pointer"
                  @click="editItem('mobile')"
                />
              </div>
            </v-text-field>
          </v-col>

          <!-- 验证码區塊 -->
          <v-col
            v-if="!disable.contact_no && validationType === 'sms'"
            cols="12"
            class="px-3 py-1"
          >
            <v-row>
              <v-col
                cols="12"
                class="pt-2 pb-2"
              >
                <span class="title--text caption font-weight-medium">
                  {{ $t("form.missCaptcha") }}
                </span>
              </v-col>

              <!-- 验证码 輸入 -->
              <v-col
                cols="8"
                class="py-0 pr-1"
              >
                <v-text-field
                  v-model.trim="mobileVerifyCodeSMS"
                  dense
                  :placeholder="$t('form.missVerifyCode')"
                  :rules="[rules.required.smsCode, rules.format.verifyMobile]"
                  single-line
                  outlined
                />
              </v-col>

              <!-- 發送驗證碼 BUTTON -->
              <v-col
                cols="4"
                class="py-0 pl-1"
              >
                <v-btn
                  :disabled="disable.verifiMobileCode"
                  block
                  outlined
                  color="primary"
                  class="px-0 caption"
                  @click="verifiMobileCode"
                >
                  <!-- 發送驗證碼 -->
                  <template v-if="verifiMobileCodeBtnSec === 60">
                    {{ $t("myCenter.sendVerificationCode") }}
                  </template>

                  <!-- 59后重試 -->
                  <template v-else>
                    {{ verifiMobileCodeBtnSec + "s" + $t("global.retry") }}
                  </template>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <!-- 电子邮箱 -->
          <v-col
            cols="12"
            class="px-3 py-1"
          >
            <span class="title--text caption font-weight-medium">
              {{ $t("myCenter.email") }}
            </span>

            <!-- 不需要驗証 (电子邮箱) -->
            <v-text-field
              v-if="validationType !== 'email'"
              v-model.trim="form.email"
              :disabled="disable.email"
              :readonly="disable.email"
              :placeholder="$t('form.missEmail')"
              :rules="[rules.required.mail, rules.format.mail]"
              dense
              class="mt-2"
              single-line
              outlined
            >
              <div
                v-if="canChangeEmail && disable.email"
                slot="append"
              >
                <Icon
                  data="@icon/edit.svg"
                  width="24px"
                  height="24px"
                  class="primary--text cursor-pointer"
                  @click="editItem('email')"
                />
              </div>
            </v-text-field>

            <!-- 需要驗証 (电子邮箱) -->
            <v-text-field
              v-else
              v-model.trim="form.email"
              :disabled="disable.email && form.email !== ''"
              :placeholder="$t('form.missEmail') "
              :rules="[rules.required.mail, rules.format.mail]"
              class="mt-2"
              dense
              single-line
              outlined
              readonly
              @click="emailDialog.status = true"
            >
              <div
                v-if="canChangeEmail && disable.email"
                slot="append"
              >
                <Icon
                  data="@icon/edit.svg"
                  width="24px"
                  height="24px"
                  class="primary--text cursor-pointer"
                  @click="editItem('email')"
                />
              </div>
            </v-text-field>
          </v-col>

          <!-- 取款密码 -->
          <v-col
            :cols="form.withdrawal_key ? 6 : 12"
            class="px-3 py-0"
          >
            <span class="title--text caption font-weight-medium">
              {{ $t("myCenter.withdrawPassword") }}
            </span>
            <v-text-field
              v-model.trim="form.withdrawal_key"
              :disabled="disable.withdrawal_key"
              :readonly="disable.withdrawal_key"
              :placeholder="$t('form.misswithdrawPassword')"
              :rules="[
                rules.required.withdrawal_key,
                rules.length.withdrawal_key,
              ]"
              class="mt-2"
              type="password"
              single-line
              dense
              outlined
            >
              <div
                v-if="canChangeWithdrawalPassword && disable.withdrawal_key"
                slot="append"
              >
                <Icon
                  data="@icon/edit.svg"
                  width="24px"
                  height="24px"
                  class="primary--text cursor-pointer"
                  @click="editItem('withdrawPassword')"
                />
              </div>
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            class="pt-0"
          >
            <!-- 已設定過 說明文字 -->
            <template v-if="disable.withdrawal_key">
              <div class="text-center caption comment--text">
                {{ $t("myCenter.contactCsInfo") }}
              </div>
            </template>

            <!-- 未設定 保存按鈕  :disabled="!veridate"-->
            <template v-else>
              <v-btn
                class="mt-2"
                block
                height="44"
                color="primary"
                :disabled="!veridate"
                @click="preSetUserProfile"
              >
                <span class="subtitle-1 btnText--text">
                  {{ $t("global.save") }}
                </span>
              </v-btn>
            </template>

            <!-- 使用者修改登入密碼-->
            <template v-if="showUserChangePasswordBtn">
              <div class="text-center caption comment--text">
                <v-btn
                  class="mt-3"
                  color="danger"
                  height="44"
                  block
                  @click="userPasswordDialog.status = true"
                >
                  {{ $t("form.modifyLoginPassword") }}
                </v-btn>
              </div>
            </template>
          </v-col>
        </v-row>
      </v-container>

      <!-- DIALOG 選擇性別 -->
      <Select
        :status="selectDialog.status"
        :height="188"
        :width="240"
      >
        <v-row
          slot="title"
          class="p-sticky top-0 bg"
        >
          <!-- 選擇性別 -->
          <v-col
            cols="12"
            class="text-h6 title--text px-6 py-4 p-sticky"
          >
            {{ $t("global.chooseGender") }}
          </v-col>
        </v-row>

        <v-row slot="list">
          <v-col
            v-for="(item, index) in selectDialog.item"
            :key="index"
            cols="12"
            class="px-6 title--text"
            :class="[{ secondary: form.gender === Object.keys(item)[0] }]"
            @click="setGender(Object.keys(item)[0], form)"
          >
            {{ Object.values(item)[0] }}
          </v-col>
        </v-row>
      </Select>

      <!-- 保存前 提醒彈窗 -->
      <Dialog
        :dialog="preSaveDialog"
        :width="280"
        :height="140"
      >
        <div
          slot="text"
          class="px-6"
        >
          {{ $t("myCenter.savePersonalInfo") }}
        </div>

        <!-- 取消 -->
        <v-btn
          slot="btn"
          text
          class="subtitle--text"
          @click="preSaveDialog = false"
        >
          {{ $t("global.cancel") }}
        </v-btn>

        <!-- 保存 發送表單 -->
        <v-btn
          slot="btn"
          text
          color="primary"
          @click="editUserProfile(form)"
        >
          {{ $t("global.confirmation") }}
        </v-btn>
      </Dialog>
    </v-form>

    <!-- 手機驗証碼 錯誤 彈窗 -->
    <Dialog
      :height="140"
      :dialog="verifyMobileCodeErrorDialog"
    >
      <div
        slot="text"
        class="px-6 text--text"
      >
        {{ $t("form.errorCaptcha") }}
      </div>

      <v-btn
        slot="btn"
        text
        color="primary"
        @click="resetMobileVerfifyStatus"
      >
        {{ $t("global.confirmation") }}
      </v-btn>
    </Dialog>

    <!-- 修改登入密碼 dialog -->
    <Dialog :dialog="userPasswordDialog.status">
      <div
        slot="text"
        class="px-6 subtitle-1 font-weight-bold"
      >
        <v-form
          ref="mdfUserPasswordForm"
          v-model="userPasswordDialog.form.status"
        >
          <!-- TITLE -->
          <p>
            {{ $t("form.modifyLoginPassword") }}
          </p>

          <!-- 驗証 信箱 === 'email' -->
          <v-row v-if="validationType === 'email'">
            <v-col
              cols="12"
              class="py-0 mt-2"
            >
              <!-- email 驗証碼 -->
              <span class="title--text caption font-weight-medium">
                {{ $t("myCenter.emailCaptcha") }}
              </span>
            </v-col>
            <v-col
              cols="6"
              class="py-0"
            >
              <v-text-field
                v-model.trim="emailDialog.form.validateCode"
                dense
                :placeholder="$t('form.missVerifyCode')"
                :rules="[rules.required.smsCode, rules.format.verifyMobile]"
                single-line
                outlined
              />
            </v-col>

            <v-col
              cols="6"
              class="pa-0 pr-4"
            >
              <v-btn
                :disabled="emailDialog.validate.sendBtnSec !== 60"
                block
                outlined
                color="primary"
                class="px-0 caption"
                @click="sendEmailValidateCode(form, 'edit')"
              >
                <!-- 發送驗證碼 -->
                <template v-if="emailDialog.validate.sendBtnSec === 60">
                  {{ $t("myCenter.sendVerificationCode") }}
                </template>

                <!-- 59后重試 -->
                <template v-else>
                  {{
                    emailDialog.validate.sendBtnSec + "s" + $t("global.retry")
                  }}
                </template>
              </v-btn>
            </v-col>
          </v-row>

          <!-- 驗証 手機 === 'sms' -->
          <v-row v-if="validationType === 'sms'">
            <v-col
              cols="12"
              class="py-0 mt-2"
            >
              <!-- 手機驗証碼 -->
              <span class="title--text caption font-weight-medium">
                {{ $t("myCenter.mobileCaptcha") }}
              </span>
            </v-col>
            <v-col
              cols="6"
              class="py-0"
            >
              <v-text-field
                v-model.trim="mobileVerifyCodeSMS"
                dense
                :placeholder="$t('form.missVerifyCode')"
                :rules="[rules.required.smsCode, rules.format.verifyMobile]"
                single-line
                outlined
              />
            </v-col>

            <v-col
              cols="6"
              class="pa-0 pr-4"
            >
              <!-- 手機發送驗証碼 -->
              <v-btn
                :disabled="verifiMobileCodeBtnSec !== 60"
                block
                outlined
                color="primary"
                class="px-0 caption"
                @click="verifiMobileCode"
              >
                <!-- 發送驗證碼 -->
                <template v-if="verifiMobileCodeBtnSec === 60">
                  {{ $t("myCenter.sendVerificationCode") }}
                </template>

                <!-- 59后重試 -->
                <template v-else>
                  {{ verifiMobileCodeBtnSec + "s" + $t("global.retry") }}
                </template>
              </v-btn>
            </v-col>
          </v-row>
          <!-- 輸入登入密碼 -->
          <div class="input">
            <!-- 原有密碼 -->
            <span class="title--text caption font-weight-medium">
              {{ $t("form.missPassword") }}
            </span>
            <v-text-field
              v-model.trim="userPasswordDialog.form.password"
              type="password"
              :placeholder="$t('form.missPassword')"
              class="mt-2"
              dense
              single-line
              outlined
              :rules="[rules.required.withdrawal_key]"
            />

            <!-- 新密碼 -->
            <span class="title--text caption font-weight-medium">
              {{ $t("form.newPassword") }}
            </span>
            <v-text-field
              v-model.trim="userPasswordDialog.form.newPassword"
              type="password"
              :placeholder="$t('form.enterNewPassword')"
              :rules="[rules.required.enterNewPassword, rules.length.pwd]"
              class="mt-2"
              dense
              single-line
              outlined
            />

            <!-- 再次輸入新密碼 -->
            <span class="title--text caption font-weight-medium">
              {{ $t("form.newPasswordConfirm") }}
            </span>
            <v-text-field
              v-model.trim="userPasswordDialog.form.newPasswordConfirm"
              type="password"
              :placeholder="$t('form.enterNewPasswordAgain')"
              :rules="[
                rules.required.enterNewPasswordAgain,
                rules.format.enterNewPasswordAgain,
              ]"
              class="mt-2"
              dense
              single-line
              outlined
              @keyup.enter="userLoginPasswordChange"
            />
          </div>
        </v-form>
      </div>

      <!-- 取消 -->
      <v-btn
        slot="btn"
        text
        class="subtitle--text"
        @click="resetUserLoginPasswordChangeDialog"
      >
        {{ $t("global.cancel") }}
      </v-btn>

      <v-btn
        slot="btn"
        text
        color="primary"
        @click="userLoginPasswordChange"
      >
        {{ $t("global.confirmation") }}
      </v-btn>
    </Dialog>

    <!-- EMAIL 驗証 -->
    <Dialog :dialog="emailDialog.status">
      <div
        slot="text"
        class="px-6 subtitle-1 font-weight-bold"
      >
        <p>{{ emailDialog.title }}</p>
        <v-form
          ref="emailDialogForm"
          v-model="emailDialog.form.status"
        >
          <v-row>
            <v-col
              cols="12"
              class="py-0 mt-2"
            >
              <!-- 新email -->
              <span class="title--text caption font-weight-medium">
                {{ $t("myCenter.email") }}
              </span>
            </v-col>
            <v-col
              cols="8"
              class="py-0"
            >
              <v-text-field
                ref="emailInput"
                v-model.trim="emailDialog.form.email"
                type="email"
                :placeholder="$t('form.missEmail')"
                :rules="[rules.required.mail, rules.format.mail]"
                dense
                single-line
                outlined
              />
            </v-col>

            <v-col
              cols="4"
              class="pa-0 pr-4"
            >
              <v-btn
                :disabled="emailDialog.validate.sendBtnSec !== 60"
                block
                outlined
                color="primary"
                class="px-0 caption"
                @click="sendEmailValidateCode(emailDialog.form)"
              >
                <!-- 發送驗證碼 -->
                <template v-if="emailDialog.validate.sendBtnSec === 60">
                  {{ $t("myCenter.sendVerificationCode") }}
                </template>

                <!-- 59后重試 -->
                <template v-else>
                  {{
                    emailDialog.validate.sendBtnSec + "s" + $t("global.retry")
                  }}
                </template>
              </v-btn>
            </v-col>

            <!-- 驗証碼 -->
            <v-col
              cols="12"
              class="py-0"
            >
              <span class="title--text caption font-weight-medium">
                {{ $t("form.missCaptcha") }}
              </span>
            </v-col>

            <!-- 验证码 輸入 -->
            <v-col
              cols="12"
              class="py-0"
            >
              <v-text-field
                v-model.trim="emailDialog.form.validateCode"
                dense
                :placeholder="$t('form.missVerifyCode')"
                :rules="[rules.required.smsCode, rules.format.verifyMobile]"
                single-line
                outlined
              />
            </v-col>
          </v-row>
        </v-form>
      </div>

      <!-- 取消 -->
      <v-btn
        slot="btn"
        text
        class="subtitle--text"
        @click="emailDialogClose"
      >
        {{ $t("global.cancel") }}
      </v-btn>

      <v-btn
        slot="btn"
        text
        color="primary"
        @click="emailDialogConfirm(emailDialog.form)"
      >
        {{ $t("global.confirmation") }}
      </v-btn>
    </Dialog>

    <!-- 修改各項目 dialog -->
    <Dialog :dialog="edit.dialogStatus">
      <div
        slot="text"
        class="px-6 subtitle-1 font-weight-bold"
      >
        <v-form
          ref="editForm"
          v-model="edit.validate"
        >
          <!-- TITLE -->
          <p>
            {{ $t('global.edit') + edit.dialogTitle }}
          </p>

          <!-- 驗証 信箱 === 'email' -->
          <v-row v-if="(validationType === 'email' && edit.type !== 'email') || (edit.type === 'mobile' && validationType !== 'none')">
            <v-col
              cols="12"
              class="py-0 mt-2"
            >
              <!-- email 驗証碼 -->
              <span class="title--text caption font-weight-medium">
                {{ $t("myCenter.emailCaptcha") }}
              </span>
            </v-col>
            <v-col
              cols="6"
              class="py-0"
            >
              <v-text-field
                v-model.trim="emailDialog.form.validateCode"
                dense
                :placeholder="$t('form.missVerifyCode')"
                :rules="[rules.required.smsCode, rules.format.verifyMobile]"
                single-line
                outlined
              />
            </v-col>

            <v-col
              cols="6"
              class="pa-0 pr-4"
            >
              <v-btn
                :disabled="emailDialog.validate.sendBtnSec !== 60"
                block
                outlined
                color="primary"
                class="px-0 caption"
                @click="sendEmailValidateCode(emailDialog.form, 'edit')"
              >
                <!-- 發送驗證碼 -->
                <template v-if="emailDialog.validate.sendBtnSec === 60">
                  {{ $t("myCenter.sendVerificationCode") }}
                </template>

                <!-- 59后重試 -->
                <template v-else>
                  {{
                    emailDialog.validate.sendBtnSec + "s" + $t("global.retry")
                  }}
                </template>
              </v-btn>
            </v-col>
          </v-row>

          <!-- 驗証 手機 === 'sms' -->
          <v-row v-if="(validationType === 'sms' && edit.type !== 'mobile' )|| (edit.type === 'email' && validationType !== 'none')">
            <v-col
              cols="12"
              class="py-0 mt-2"
            >
              <!-- 手機驗証碼 -->
              <span class="title--text caption font-weight-medium">
                {{ $t("myCenter.mobileCaptcha") }}
              </span>
            </v-col>
            <v-col
              cols="6"
              class="py-0"
            >
              <v-text-field
                v-model.trim="mobileVerifyCodeSMS"
                dense
                :placeholder="$t('form.missVerifyCode')"
                :rules="[rules.required.smsCode, rules.format.verifyMobile]"
                single-line
                outlined
              />
            </v-col>

            <v-col
              cols="6"
              class="pa-0 pr-4"
            >
              <!-- 手機發送驗証碼 -->
              <v-btn
                :disabled="verifiMobileCodeBtnSec !== 60"
                block
                outlined
                color="primary"
                class="px-0 caption"
                @click="verifiMobileCode"
              >
                <!-- 發送驗證碼 -->
                <template v-if="verifiMobileCodeBtnSec === 60">
                  {{ $t("myCenter.sendVerificationCode") }}
                </template>

                <!-- 59后重試 -->
                <template v-else>
                  {{ verifiMobileCodeBtnSec + "s" + $t("global.retry") }}
                </template>
              </v-btn>
            </v-col>
          </v-row>

          <!-- 修改手機 -->
          <template v-if="edit.type === 'mobile'">
            <div class="mt-2">
              <span class="title--text caption font-weight-medium">
                {{ $t("myCenter.newMobile") }}
              </span>
              <v-text-field
                v-model.trim="edit.form.contact_no"
                :placeholder="$t('form.missMobile')"
                class="mt-2"
                dense
                single-line
                outlined
                :rules="[
                  rules.required.mobile,
                  rules.format.mobile,
                ]"
              />
            </div>
          </template>

          <!-- 修改信箱 -->
          <template v-if="edit.type === 'email'">
            <div class="mt-2">
              <span class="title--text caption font-weight-medium">
                {{ $t('global.editNew') + $t("myCenter.email") }}
              </span>
              <v-text-field
                v-model.trim="edit.form.email"
                :placeholder="$t('form.missEmail')"
                class="mt-2"
                dense
                single-line
                outlined
                :rules="[rules.required.mail, rules.format.mail]"
              />
            </div>
          </template>

          <!-- 修改姓名 -->
          <template v-if="edit.type === 'username'">
            <div class="mt-2">
              <span class="title--text caption font-weight-medium">
                {{ $t('global.editNew') + $t("myCenter.bankAccountName") }}
              </span>
              <v-text-field
                v-model.trim="edit.form.username"
                :placeholder="$t('form.missName')"
                class="mt-2"
                dense
                single-line
                outlined
                :rules="[rules.required.userName, rules.length.userName]"
              />
            </div>
          </template>

          <!-- 修改性別 -->
          <template v-if="edit.type === 'gender'">
            <div class="mt-2">
              <span class="title--text caption font-weight-medium">
                {{ $t("myCenter.gender") }}
              </span>
              <v-select
                v-model="edit.form.gender"
                :items="genderItems"
                item-text="gender"
                item-value="value"
                dense
                outlined
              />
            </div>
          </template>

          <!-- 修改出生日期 -->
          <template v-if="edit.type === 'birthday'">
            <div class="mt-2">
              <span class="title--text caption font-weight-medium">
                {{ $t("myCenter.birthday") }}
              </span>
              <!-- 出生日期 -->
              <v-col
                cols="12"
                class="py-0 px-0"
              >
                <!-- 日期選擇器彈窗 -->
                <v-dialog
                  ref="dialogFrom"
                  v-model="datePicker"
                  persistent
                  width="290px"
                >
                  <!-- input -->
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="edit.form.birthdate"
                      :placeholder="$t('form.missBirthday')"
                      :rules="[rules.required.birthdate]"
                      class="mt-2"
                      dense
                      single-line
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>

                  <!-- 日期選擇器 -->
                  <v-date-picker
                    v-model="edit.form.birthdate"
                    class="bg"
                    :max="legaAge"
                    header-color="bg"
                    color="secondary"
                    scrollable
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="subtitle"
                      class="subtitle-1 font-weight-medium"
                      @click="chancelEditPickerDate"
                    >
                      {{ $t("global.cancel") }}
                    </v-btn>

                    <v-btn
                      class="subtitle-1 font-weight-medium"
                      text
                      color="primary"
                      @click="datePicker = false"
                    >
                      {{ $t("global.confirmation") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </div>
          </template>

          <!-- 修改取款密碼 -->
          <div
            v-if="edit.type ==='withdrawPassword'"
            class="input"
          >
            <!-- 新取款密碼 -->
            <span class="title--text caption font-weight-medium">
              {{ $t('myCenter.newWithdrawPassword') }}
            </span>
            <v-text-field
              v-model.trim="edit.form.withdrawal_key"
              type="password"
              :placeholder="$t('myCenter.newWithdrawPassword')"
              :rules="[rules.required.enterNewPassword, rules.length.pwd]"
              class="mt-2"
              dense
              single-line
              outlined
            />

            <!-- 再次輸入新取款密碼 -->
            <span class="title--text caption font-weight-medium">
              {{ $t("myCenter.newWithdrawPasswordConfirm") }}
            </span>
            <v-text-field
              v-model.trim="edit.check_withdrawal_key"
              type="password"
              :placeholder="$t('myCenter.newWithdrawPasswordConfirm')"
              :rules="[
                rules.required.enterNewPasswordAgain,
                rules.format.enterNewWithdrawKeyAgain,
              ]"
              class="mt-2"
              dense
              single-line
              outlined
              @keyup.enter="userLoginPasswordChange"
            />
          </div>
        </v-form>
      </div>

      <!-- 取消 -->
      <v-btn
        slot="btn"
        text
        class="subtitle--text"
        @click="chancelEditDialog"
      >
        {{ $t("global.cancel") }}
      </v-btn>

      <v-btn
        slot="btn"
        text
        color="primary"
        @click="editUserProfileItem(edit.form)"
      >
        {{ $t("global.confirmation") }}
      </v-btn>
    </Dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { resStatus } from '../../../utils/resUtils'
import { validateMobileNumberByNation } from '../../../utils/validate'
import Dialog from '@/components/dialog'
import Select from '@/components/dialog/Select'
import validator from 'validator'

export default {
  components: {
    Select,
    Dialog,
  },

  data: () => ({
    veridate: false, // 表單驗証

    form: {
      username: '', // 使用者姓名
      gender: '', // 性別 (男：male 女：female)
      birthdate: '', // 生日 (日期格式：YYYY-MM-DD，例"1998-02-02")
      contact_no: '', // 手機號碼
      email: '', // 電子信箱
      withdrawal_key: '', // 取款密碼 (必須六位數)
    },
    mobileVerifyCodeSMS: '', // 手機驗証碼
    varifyCodeStatus: true,
    verifyCodeFetchTimeId: '',

    verifiMobileCodeBtnSec: 60, // 倒數秒數
    timeId: '', // 計時器

    disable: {
      username: false,
      gender: false,
      birthdate: false,
      contact_no: false,
      email: false,
      withdrawal_key: false,
      verifiMobileCode: true,
    },

    preSaveDialog: false,
    dialog: false, // selectDialog.status (不直接在此操作)
    datePicker: false,

    // 驗証碼錯誤彈窗
    verifyMobileCodeErrorDialog: false,
    userPasswordDialog: {
      status: false,
      form: {
        status: false,
        password: '',
        newPassword: '',
        newPasswordConfirm: '',
      },
    },
    emailDialog: {
      status: false,
      title: '新增电子邮箱',
      validate: {
        timeID: null,
        codeBtn: true,
        sendBtnSec: 60,
      },
      form: {
        status: false,
        email: '',
        validateCode: '',
      },
    },

    // 修改資料
    edit: {
      dialogStatus: false,
      type: null,
      dialogTitle: null,
      validate: false,
      form: {
        birthdate: null,
      },
      check_withdrawal_key: null,
    },
    picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
  }),

  computed: {
    ...mapState({
      // 來自 api 的驗証方式 none email sms
      validationType: state => state.websiteData.validation_method,

      // 修改項目 狀態
      canChangeUsername: state => state.websiteData.func_switch.allow_member_change_username, // 姓名
      canChangeGender: state => state.websiteData.func_switch.allow_member_change_gender, // 性別
      canChangeBirthday: state => state.websiteData.func_switch.allow_member_change_birthdate, // 生日
      canChangePhoneNumber: state => state.websiteData.func_switch.allow_member_change_contact_no, // 手機
      canChangeEmail: state => state.websiteData.func_switch.allow_member_change_email, // 電子信箱
      canChangeWithdrawalPassword: state => state.websiteData.func_switch.allow_member_change_withdrawal_key, // 取款密碼
      mobileFormatNation: state => state.websiteData.phone_format_nation,
    }),

    ...mapGetters([
      'lang', // 目前設定語系
      'allowMemberChangePassword', // 允許修改使用者密碼
    ]),

    /**
     * 性別 選擇單窗 資料
     * @date 2021-05-21
     * @returns {Object} 性別資料、彈窗狀態
     */
    selectDialog: {
      get() {
        return {
          status: this.dialog,

          item: [
            { male: this.$t('myCenter.male') },
            { female: this.$t('myCenter.female') },
          ],
        }
      },

      /**
       * 描述
       * @date 2021-05-21
       * @param {String} status  性別彈窗狀態操作
       */
      set(status) {
        this.dialog = status
      },
    },

    /**
     * 在性別 input 的顯示
     * @date 2021-05-21
     * @returns {String} 目前選擇的性別(form.gender)，無 => 為空
     */
    genderDisplay() {
      const genderItem = this.selectDialog.item.filter(
        item => Object.keys(item)[0] === this.form.gender,
      )[0]
      return genderItem ? Object.values(genderItem)[0] : ''
    },

    // 滿十八歲日期
    legaAge() {
      const year = new Date().getFullYear() - 18
      const mon = new Date().getMonth() + 1
      const day = new Date().getDate() - 1
      return `${year}-${mon < 10 ? '0' + mon : mon}-${
        day < 10 ? '0' + day : day
      }`
    },

    // 表單驗證
    rules() {
      return {
        // 必填
        required: {
          userName: value => !!value || this.$t('form.missName'),
          gender: value => !!value || this.$t('form.missGender'),
          birthdate: value => !!value || this.$t('form.missBirthday'),
          mobile: value => !!value || this.$t('form.missMobile'),
          smsCode: value => !!value || this.$t('form.missVerifyCode'),
          mail: value => !!value || this.$t('form.missEmail'),
          withdrawal_key: val => !!val || this.$t('form.missPassword'),
          enterNewPassword: val => !!val || this.$t('form.enterNewPassword'),
          enterNewPasswordAgain: val =>
            !!val || this.$t('form.enterNewPasswordAgain'),
        },
        // 長度
        length: {
          userName: value =>
            (value && value.length > 1) || this.$t('form.errorName'),
          withdrawal_key: val =>
            (val && val.length > 5) || this.$t('form.errorPasswordFormat'),
          pwd: value =>
            (value && value.length > 5) || this.$t('form.errorPasswordFormat'),
        },
        // 格式
        format: {
          /**
           * 手機號碼格式確認 (中國電話 13575769526)
           * @date 2021-05-25
           * @param {string} phoneNumber 手機號碼
           * @returns {boolean} 是否符合手機格式
           */
          mobile: phoneNumber => {
            // TODO 泰國語系尚未調整為 'th-TH' (確認 api wtoken/lang 是否為語系代碼) => 調整回 this.lang
            const validate = validateMobileNumberByNation(phoneNumber, this.mobileFormatNation)
            this.$log(`手號碼驗證: ${validate}`)
            // 發送驗証碼 狀態 (如果不在計時中)
            if (this.verifiMobileCodeBtnSec === 60) {
              this.disable.verifiMobileCode = !validate
            }
            return validate || this.$t('form.errorMobile')
          },
          mail: email =>
            (email && validator.isEmail(email)) || this.$t('form.errorEmail'),
          verifyMobile: code =>
            !!this.varifyCodeStatus || this.$t('form.errorCaptcha'),
          enterNewPasswordAgain: password => {
            if (password === this.userPasswordDialog.form.newPassword) {
              return true
            }
            return this.$t('form.errorEnterNewPasswordAgain')
          },

          enterNewWithdrawKeyAgain: password => {
            if (password === this.edit.form.withdrawal_key) return true
            return this.$t('form.errorEnterNewPasswordAgain')
          },
        },
      }
    },

    // 是否顯示 修改登入密碼 按鈕
    showUserChangePasswordBtn() {
      if (!this.allowMemberChangePassword) return false
      if (this.allowMemberChangePassword && (this.validationType === 'sms' && !this.disable.verifiMobileCode)) return false
      if (this.allowMemberChangePassword && (this.validationType === 'email' && !this.disable.email)) return false
      if (this.allowMemberChangePassword) return true

      return false
    },

    genderItems() {
      return [
        { gender: this.$t('myCenter.male'), value: 'male' },
        { gender: this.$t('myCenter.female'), value: 'female' },

      ]
    },

    i18nLocal() {
      return this.$i18n.locale
    },
  },

  watch: {
    datePicker() {
      this.$nextTick(() => document.querySelector('.v-picker__title').classList.add('title--text'))
    },
  },

  mounted() {
    this.getUserBaseInfo()
  },

  methods: {
    ...mapActions([
      'get_user_base_info',
      'edit_user_profile',
      'get_mobile_verify_SMS',
      'verify_mobile_code',
      'show_alert',
      'modify_user_password',
      'user_logout',
      'send_email_validate_code',
      'validate_email_validate_code',
    ]),

    chancelEditPickerDate() {
      this.edit.form.birthdate = null
      this.datePicker = false
    },

    chancelEditDialog() {
      this.edit.type = ''
      this.$refs.editForm.reset()
      this.edit.dialogStatus = false
    },

    // 修改項目
    editItem(type) {
      if (!type) return false
      this.edit.type = type

      // 預帶入必填項目
      if (this.edit.type !== 'username') this.edit.form.username = this.form.username
      if (this.validationType === 'email') this.emailDialog.form.email = this.form.email
      this.edit.form.contact_no = this.form.contact_no
      this.edit.form.email = this.form.email
      this.edit.form.birthdate = this.form.birthdate
      this.edit.form.gender = this.form.gender
      this.edit.form.withdrawal_key = this.form.withdrawal_key
      if (this.edit.type === 'withdrawPassword') this.edit.form.withdrawal_key = ''
      this.edit.dialogTitle = (type === 'username') ? this.$t('myCenter.bankAccountName') : this.$t(`myCenter.${type}`)
      this.edit.dialogStatus = true
    },

    // 送出修改
    async editUserProfileItem(editForm) {
      // 表單驗証
      this.$refs.editForm.validate()
      if (!this.edit.validate) return this.editUserProfileFail()

      // 信箱驗証
      if ((this.validationType === 'email' && this.edit.type !== 'email') || (this.edit.type === 'mobile' && this.validationType !== 'none')) {
        const { status } = await this.validate_email_validate_code(this.emailDialog.form)
        if (status === '0') return this.validateEmailCodeFail()
      }

      // 手機驗証
      if ((this.validationType === 'sms' && this.edit.type !== 'mobile') || (this.edit.type === 'email' && this.validationType !== 'none')) await this.finalCheckMobileValidateCode()

      // 如果驗証碼 驗証錯誤 或 開啟驗証碼錯誤彈窗
      if (!this.varifyCodeStatus || (this.validationType === 'sms' && this.verifyMobileCodeErrorDialog)) {
        this.mobileVerifyCodeSMS = this.mobileVerifyCodeSMS + ' '
        return false
      }

      const res = await this.edit_user_profile(editForm)
      resStatus(res, this.editUserProfileSuccess, this.editUserProfileFail)
    },

    /**
     * 設置性別功能
     * @date 2021-05-26
     * @param {any} gender
     * @param {any} form
     */
    setGender(gender, form) {
      form.gender = gender
      this.selectDialog = false
    },

    /**
     * 倒數功能 (發送驗証碼)
     * @date 2021-05-26
     */
    reciprocal() {
      // disable 發送驗証碼 btn
      this.disable.verifiMobileCode = true

      const timeId = setInterval(() => {
        if (this.verifiMobileCodeBtnSec === 0) {
          // 如果為 0 即重置
          clearInterval(this.timeId)
          this.verifiMobileCodeBtnSec = 60
          this.disable.verifiMobileCode = false

          // 每秒遞減
        } else this.verifiMobileCodeBtnSec--
      }, 1000)

      this.timeId = timeId
    },

    /**
     * 發送 sms 簡訊 (成功)
     * @date 2022-01-14
     * @param {array} data 成功信息
     */
    sendMobileVerifyCodeSuccess(data) {
      this.show_alert({
        icon: 'done',
        text: data[0],
      })
    },

    /**
     * 發送 sms 簡訊 (失敗)
     * @date 2022-01-14
     * @param {string} errorsMessage 失敗信息
     */
    sendMobileVerifyCodeFail(errorsMessage) {
      this.show_alert({
        icon: 'fail',
        text: errorsMessage,
      })
    },

    /**
     * 發送 sms 簡訊驗証
     * @date 2021-05-26
     * @param {String} phone 手機號碼
     */
    async sendMobileVerifyCode(phone) {
      const res = await this.get_mobile_verify_SMS(phone)
      resStatus(
        res,
        this.sendMobileVerifyCodeSuccess,
        this.sendMobileVerifyCodeFail,
      )
    },

    /**
     * 延遲 api 事件 (停止輸入一秒後打)
     * @date 2021-05-26
     * @param {Object} phoneAndCode 手機、驗証碼
     */
    delayFetch(phoneAndCode, fun) {
      if (!phoneAndCode.code) return false
      clearTimeout(this.verifyCodeFetchTimeId)

      this.verifyCodeFetchTimeId = setTimeout(() => {
        fun(phoneAndCode)
      }, 1000)
    },

    /**
     * 如果驗証碼 (正確)
     * @date 2021-05-26
     */
    verifyMobileCodeSuccess() {
      this.varifyCodeStatus = true
    },

    /**
     * 如果驗証碼 (錯誤)
     * @date 2021-05-26
     */
    verifyMobileCodeFail() {
      // 驗証錯誤彈窗 (開啟)
      this.verifyMobileCodeErrorDialog = true
      // 驗証錯誤碼狀態 fail
      this.varifyCodeStatus = false
    },

    /**
     * 重置驗証錯誤狀態 (驗証錯誤彈窗=>確認)
     * @date 2021-09-23
     */
    resetMobileVerfifyStatus() {
      // 驗証錯誤碼狀態 done (狀態回復)
      this.varifyCodeStatus = true
      // 驗証碼輸入 重置
      this.mobileVerifyCodeSMS = ''
      // 驗証表單驗証 (提醒使用者輪入)
      this.$refs.form.validate()
      // 開啟驗証錯誤彈窗 (關閉)
      this.verifyMobileCodeErrorDialog = false
    },

    /**
     * 驗証 手機驗証碼是否正確
     * @date 2021-05-26
     * @param {Object} phoneAndCode 手機、驗証碼
     */
    async verifyMobileCode(phoneAndCode) {
      const res = await this.verify_mobile_code(phoneAndCode)
      resStatus(res, this.verifyMobileCodeSuccess, this.verifyMobileCodeFail)
    },

    /**
     * 發送驗証碼 按鈕
     * 倒數 / 狀態重置
     * @date 2021-05-26
     * @returns {any}
     */
    verifiMobileCode() {
      // 發送 sms 簡訊
      this.sendMobileVerifyCode({
        phone: this.form.contact_no,
      })

      // 倒數計時
      this.reciprocal()
    },

    /**
     * 使用者基本資料 寫入 form
     * @date 2021-05-20
     * @param {Object} form 修改基本資料表單
     * @param {Object} baseInfo 使用基本資料
     */
    userBaseInfoWriteToForm(form, baseInfo) {
      for (const item in baseInfo) {
        // 如果 api 索引 符合 form 索引 且 有資料
        if (form[item] !== undefined && baseInfo[item]) {
          // api 來的資料，input 將默認 disable
          this.disable[item] = true

          // 取款密碼特別處理
          if (item === 'withdrawal_key') return (form[item] = '123456')
          form[item] = baseInfo[item] || ''
        }
      }
    },

    /**
     * 取得使用者基本資料 (成功)
     * @date 2021-05-20
     * @param {Object} {member} 使用者基本資料
     */
    getUserBaseInfoSuccess({ member }) {
      // 基本資料 與 修改資料 索引不同 (修改)
      member.withdrawal_key = member.withdrawal_password
      // 取得的資料寫入 form
      this.userBaseInfoWriteToForm(this.form, member)
    },

    /**
     * 取得使用者基本資料
     * @date 2021-05-20
     */
    async getUserBaseInfo() {
      const res = await this.get_user_base_info()
      resStatus(res, this.getUserBaseInfoSuccess)
    },

    /**
     * 使用者修改個人資料 (成功)
     * @date 2021-05-26
     */
    editUserProfileSuccess() {
      this.show_alert({
        icon: 'done',
      })
      if (this.edit.type) this.chancelEditDialog() // 如果是修改的話

      Object.assign(this.form, this.$options.data().form)
      this.$refs.form.resetValidation()
      this.getUserBaseInfo()
    },

    /**
     * 使用者修改個人資料 (失敗)
     * @date 2021-05-26
     * @returns {any}
     */
    editUserProfileFail() {
      this.show_alert({
        icon: 'fail',
      })
    },

    // 手機確認驗証碼
    async finalCheckMobileValidateCode() {
      console.log('finalCheckMobileValidateCode')
      if (this.validationType === 'sms' || (this.edit.type === 'email' && this.validationType !== 'none')) {
        await this.verifyMobileCode({
          phone: this.form.contact_no,
          code: this.mobileVerifyCodeSMS,
        })
      }

      // 如果驗証碼 驗証錯誤 或 開啟驗証碼錯誤彈窗
      if (!this.varifyCodeStatus || (this.validationType === 'sms' && this.verifyMobileCodeErrorDialog)) {
        this.mobileVerifyCodeSMS = this.mobileVerifyCodeSMS + ' '
        return false
      }
    },

    // 保存前最確認
    async preSetUserProfile() {
      if (this.validationType === 'sms') {
        await this.verifyMobileCode({
          phone: this.form.contact_no,
          code: this.mobileVerifyCodeSMS,
        })
      }

      // 如果驗証碼 驗証錯誤 或 開啟驗証碼錯誤彈窗
      if (!this.varifyCodeStatus || (this.validationType === 'sms' && this.verifyMobileCodeErrorDialog)) {
        this.mobileVerifyCodeSMS = this.mobileVerifyCodeSMS + ' '
        return false
      }

      this.preSaveDialog = true
    },

    /**
     * (保存) 使用者修改個人資料
     * @date 2021-05-26
     * @param {Object} form 使用者資料
     */
    async editUserProfile(form) {
      // 關閉最後確認彈窗
      this.preSaveDialog = false
      // 驗証表單
      this.$refs.form.validate()
      // 如果表單沒有驗証過 或 sms 驗証碼沒有過
      if (!this.veridate) return this.editUserProfileFail()

      const res = await this.edit_user_profile(form)
      resStatus(res, this.editUserProfileSuccess, this.editUserProfileFail)
    },

    /**
     * 使用者取消修改密碼 (dialog)
     * @date 2021-12-27
     */
    resetUserLoginPasswordChangeDialog() {
      this.$refs.mdfUserPasswordForm.reset()
      this.userPasswordDialog.status = false
    },

    /**
     * 使用者修改登入密碼 (失敗)
     * @date 2021-12-27
     * @param {string} error
     */
    userLoginPasswordChangeFail(errors) {
      this.failDialog(errors)
      this.resetUserLoginPasswordChangeDialog()
    },

    /**
     * 使用者修改登入密碼 (成功)
     * @date 2021-12-27
     * @param {object} data
     */
    userLoginPasswordChangeSuccess(data) {
      this.resetUserLoginPasswordChangeDialog()
      this.userLogout()
    },

    /**
     * 使用者(修改)登入密碼
     * @date 2021-12-27
     */
    async userLoginPasswordChange() {
      if (this.validationType === 'sms') {
        await this.verifyMobileCode({
          phone: this.form.contact_no,
          code: this.mobileVerifyCodeSMS,
        })
      }

      // 如果驗証碼 驗証錯誤 或 開啟驗証碼錯誤彈窗
      if ((this.validationType === 'sms' && this.verifyMobileCodeErrorDialog)) {
        this.mobileVerifyCodeSMS = this.mobileVerifyCodeSMS + ' '
        return false
      }

      // 信箱驗証
      if (this.validationType === 'email') {
        this.emailDialog.form.email = this.form.email
        const { status } = await this.validate_email_validate_code(this.emailDialog.form)
        if (status === '0') return this.validateEmailCodeFail()
      }

      this.$refs.mdfUserPasswordForm.validate()

      if (!this.userPasswordDialog.form.status) return false

      const res = await this.modify_user_password(this.userPasswordDialog.form)
      resStatus(
        res,
        this.userLoginPasswordChangeSuccess,
        this.userLoginPasswordChangeFail,
      )
    },

    /**
     * 錯誤彈窗
     * @date 2021-12-27
     */
    failDialog(errors) {
      if (!errors) {
        return this.show_alert({
          icon: 'fail',
        })
      }

      this.show_alert({
        icon: 'fail',
        text: errors,
      })
    },

    /**
     * 使用者登出 (成功)
     * @date 2021-12-27
     */
    userLogoutSuccess() {
      this.userBaseInfo = []
      this.show_alert({
        icon: 'done',
      })

      setTimeout(() => {
        this.$router.push({ path: '/login' })
      }, 500)
    },

    /**
     * 使用者登出
     * @date 2021-12-27
     */
    async userLogout() {
      const res = await this.user_logout()
      resStatus(res, this.userLogoutSuccess, this.failDialog)
    },

    /**
     * (失敗) 取得 email 驗証碼
     * @date 2022-01-25
     * @param {array} errors 錯誤訊息
     */
    sendEmailValidateCodeFail(errors) {
      this.emailDialog.form.email = ''

      this.show_alert({
        icon: 'fail',
        text: errors[0],
      })
    },

    /**
     * (發送) email 驗証碼
     * @date 2022-01-07
     * @param {object} emailForm  { email }
     */
    async sendEmailValidateCode(emailForm, type) {
      const { email } = emailForm

      // 有值且驗証成功才送請求
      if (type !== 'edit') {
        if (!this.$refs.emailInput.validate()) return false
      }
      if (!email) return false

      this.reciprocalEmailSendValidateCodeBtn()

      const res = await this.send_email_validate_code(emailForm)

      // 成功取得 email 驗証碼
      if (res.status === '1') {
        return this.show_alert({
          icon: 'done',
        })
      }

      // 無法取得 email 驗証碼
      this.sendEmailValidateCodeFail(res.errors)
    },

    resetEmailForm() {
      this.emailDialog.status = false
      this.$refs.emailDialogForm.reset()
    },

    /**
     * 新增 email Dialog (CANCEL)
     * @date 2022-01-07
     */
    emailDialogClose() {
      this.resetEmailForm()
    },

    /**
     * 新增 email Dialog (確認)
     * @date 2022-01-07
     * @param {object} formData email 驗証資料 {email, code}
     */
    async emailDialogConfirm(formData) {
      this.$refs.emailDialogForm.validate()
      if (!this.emailDialog.form.status) return false
      this.validateEmailCode(formData)
    },

    /**
     * 驗証 email 驗証碼 (成功)
     * @date 2022-01-07
     */
    validateEmailCodeSuccess() {
      this.show_alert({
        icon: 'done',
      })
      this.form.email = this.emailDialog.form.email
      this.resetEmailForm()
    },

    /**
     * 驗証 email 驗証碼 (失敗)
     * @date 2022-01-07
     * @param {string} error
     */
    validateEmailCodeFail(errors) {
      this.emailDialog.form.validateCode = ''

      this.show_alert({
        icon: 'fail',
        text: errors,
      })
    },

    /**
     * 驗証 email 驗証碼
     * @date 2022-01-07
     * @param {object} formData { email, 驗証碼}
     */
    async validateEmailCode(formData) {
      const validateData = await this.validate_email_validate_code(formData)
      resStatus(
        validateData,
        this.validateEmailCodeSuccess,
        this.validateEmailCodeFail,
      )
    },

    /**
     * email 驗証倒數功能
     * @date 2022-01-07
     */
    reciprocalEmailSendValidateCodeBtn() {
      // disable 發送驗証碼 btn
      this.emailDialog.validate.codeBtn = true

      const timeId = setInterval(() => {
        if (this.emailDialog.validate.sendBtnSec === 0) {
          // 如果為 0 即重置
          clearInterval(this.emailDialog.validate.timeID)
          this.emailDialog.validate.sendBtnSec = 60
          this.emailDialog.validate.codeBtn = false

          // 每秒遞減
        } else this.emailDialog.validate.sendBtnSec--
      }, 1000)

      this.emailDialog.validate.timeID = timeId
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
